<template>
  <div>
    <!-- User Details -->
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="imgProfile"
            :text="avatarText(user.firstName)"
            :variant="`light-${resolveUserRoleVariant(user.role)}`"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ userData.firstName + " " + userData.lastName }}
        </h4>
        <div class="d-flex flex-wrap">
          <!-- <b-button variant="primary" @click="$refs.refInputEl.click()" class="buttons-edit">
            <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
            <span class="d-none d-sm-inline">Update</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button> -->
          <!-- <b-button variant="danger" class="buttons-danger ml-1" @click="removeUser">
            <span class="d-none d-sm-inline">Remove</span>
            <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
          </b-button> -->
        </div>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Email -->
          <b-col cols="12" md="4">
            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" v-model="user.email" type="email" readonly />
            </b-form-group>
          </b-col>
          <!-- Field: Title -->
          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="Title" rules="required">
              <b-form-group label="Title" label-for="Title">
                <v-select
                  v-model="user.title"
                  :options="titleCombo"
                  label="title"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="First Name"
              id="firstName"
              rules="required"
            >
              <b-form-group label="First Name" label-for="firstName">
                <b-form-input
                  id="firstName"
                  v-model="user.firstName"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="Last Name" rules="required">
              <b-form-group label="Last Name" label-for="lastName">
                <b-form-input
                  id="lastName"
                  v-model="user.lastName"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="Contact Number" rules="required">
              <b-form-group label="Contact Number" label-for="Contact-Number">
                <b-form-input
                  id="contact-number"
                  v-model="user.contactNumber"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="Date of Birth" rules="required">
              <b-form-group>
                <p class="mb-0" style="font-size: 12px">Date of Birth</p>
                <flat-pickr
                  class="form-control"
                  v-model="user.dob"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <div class="mt-2" v-if="currentMove['verifiedDetails']">
        <b-button
          type="submit"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 buttons-edit"
          @click.prevent="submitChanges('Modifiying User Profile')"
          :disabled="invalid"
        >
          <span>Save Changes</span>
        </b-button>
        <!-- <b-button variant="danger" class="buttons-danger" type="reset"> Reset </b-button> -->
      </div>
    </validation-observer>

    <!-- Modify Move Details -->
    <validation-observer
      ref="formModifyLastMove"
      #default="{ invalid }"
      v-if="!this.lastMove.verifiedDetails && submissionDate > dayCondition"
    >
      <h4 class="mt-3 mb-1">My move on {{ lastMove.moveDetails["moveInDate"] }}</h4>

      <b-form>
        <b-row>
          <!-- <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Email"
              id="email"
              rules="required|email"
            >
              <b-form-group label="Email" label-for="Email">
                <b-form-input
                  id="email"
                  v-model="newEmail"
                  :state="errors.length > 0 ? false : null"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="newMoveFromAddress" rules="required">
              <b-form-group label="Previous Address" label-for="newMoveFromAddress">
                <b-form-input v-model="newMoveFromAddress" type="text" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="newMoveFromPostcode" rules="required">
              <b-form-group label="Previous Address Postcode" label-for="newMoveFromPostcode">
                <b-form-input v-model="newMoveFromPostcode" type="text" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="Move Out Date" rules="required">
              <b-form-group>
                <p class="mb-0" style="font-size: 12px">Move Out Date</p>
                <flat-pickr class="form-control" v-model="newMoveOutDate" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="newMoveToAddress" rules="required">
              <b-form-group label="New Address" label-for="newMoveToAddress">
                <b-form-input v-model="newMoveToAddress" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="newMoveToPostcode" rules="required">
              <b-form-group label="New Address Postcode" label-for="newMoveToPostcode">
                <b-form-input v-model="newMoveToPostcode" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider #default="{ errors }" name="Move In Date" rules="required">
              <b-form-group>
                <p class="mb-0" style="font-size: 12px">Move In Date</p>
                <flat-pickr class="form-control" v-model="newMoveInDate" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <div class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 buttons-edit"
          @click.prevent="submitChanges()"
          :disabled="invalid || loading"
        >
          <span class="mr-1">Confirm my Details</span>
          <div v-if="loading" class="spinner-border spinner-border-sm" role="status"></div>
        </b-button>
        <!-- <b-button variant="danger" class="buttons-danger" type="reset"> Reset </b-button> -->
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { onMounted, ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
import flatPickr from "vue-flatpickr-component";
import axiosCustom from "@axios";
import store from "@/store";
import { mapState } from "vuex";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      newEmail: "",
      newMoveFromAddress: "",
      newMoveFromPostcode: "",
      newMoveToAddress: "",
      newMoveToPostcode: "",
      newMoveInDate: "",
      newMoveOutDate: "",
      submissionDate: "",
      dayCondition: "",
    };
  },
  mounted() {
    this.newEmail = this.lastMove.userDetails["email"];
    this.newMoveFromAddress = this.lastMove.moveDetails["moveFromAddress"];
    this.newMoveFromPostcode = this.lastMove.moveDetails["moveFromPostCode"];
    this.newMoveToAddress = this.lastMove.moveDetails["moveToAddress"];
    this.newMoveToPostcode = this.lastMove.moveDetails["moveToPostCode"];
    this.newMoveInDate = this.lastMove.moveDetails["moveInDate"];
    this.newMoveOutDate = this.lastMove.moveDetails["moveOutDate"];

    this.submissionDate = new Date(this.lastMove.submissionDateFormatted);
    this.dayCondition = new Date(2022, 7, 9, 0); //<- equivalent to 9/8/2022 - in Javascript the months are like the indices of an array start from 0.
  },
  computed: {
    ...mapState("app", ["currentMove", "lastMove"]),
  },
  methods: {
    toastSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          text: "🦥 Your changes have been applied.",
        },
      });
    },
    dataPayload() {

      let body = {
        ...this.user,
        moveFromAddress: this.newMoveFromAddress,
        moveFromPostcode: this.newMoveFromPostcode,
        moveToAddress: this.newMoveToAddress,
        moveToPostcode: this.newMoveToPostcode,
        moveInDate: this.newMoveInDate,
        moveOutDate: this.newMoveOutDate,
      }

      console.log(body)
      return JSON.stringify(body)
    },
    async submitChanges(mode) {

      const isValid = await this.$refs.observer.validate();

      if (!isValid) return;

      this.loading = true;

      axiosCustom
        .post("api/user/modify", this.dataPayload())
        .then((response) => console.log("user/modify", response))
        .then((result) => {
          axiosCustom
            .get("api/user/getDetails", { params: { email: this.user["email"] } })
            .then((res) => {
              const moves = res.data.moves;
              // console.log(moves);
              const move = moves[moves.length - 1];

              this.$store.commit("app/setField", ["currentMove", move]);
              this.$store.commit("app/setField", ["lastMove", move]);

              this.toastSuccess();
              this.loading = false;

              if (mode != "Modifiying User Profile") {
                this.$router.push("/dashboard/home");
              }
              
            })
        })
        .catch((error) => {
          console.log("error", error)

          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong, please try again later",
          })

          axiosCustom
            .get("api/user/getDetails", { params: { email: this.user["email"] } })
            .then((res) => {
              const moves = res.data.moves;
              const move = moves[moves.length - 1];
              this.$store.commit("app/setField", ["currentMove", move]);
              this.$store.commit("app/setField", ["lastMove", move]);
              this.loading = false;
            })
        })
;
    },
    removeUser() {
      this.$swal({
        title: "Do you really want to be removed from SlothMove?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-swal",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.toastSuccess();
          axiosCustom
            .post("api/user/remove", JSON.stringify(this.user))
            .then((res) => {
              console.log("User Remove", res);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64;
    });

    let selected = ref({ title: "Please select an option" });
    let titleCombo = [
      { title: "Mr" },
      { title: "Mrs" },
      { title: "Miss" },
      { title: "Ms" },
      { title: "Dr" },
      { title: "Professor" },
      { title: "Lady" },
      { title: "Dame" },
      { title: "Sir" },
      { title: "Judge" },
      { title: "Lord" },
    ].map((item) => item.title);
    let imgProfile = require(`@/assets/images/profile/slot-profile.png`);

    // let user = ref(props.userData);

    let user = ref({
      email: props.userData["email"],
      contactNumber: props.userData["phone"],
      firstName: props.userData["firstName"],
      lastName: props.userData["lastName"],
      title: props.userData["title"],
      dob: props.userData["dob"],
    });

    onMounted(() => {
      axiosCustom
        .get("api/user/getDetails", { params: { email: props.userData["email"] } })
        .then((res) => {

          console.log("getDetails",res.data);

          if (res.data["dateOfBirth"]) {

            user.value.dob = res.data["dateOfBirth"];

          }
        })
        .catch((err) => console.log(err));
    });

    // const lastMove = computed(() => store.state.app.lastMove);

    return {
      imgProfile,
      selected,
      titleCombo,
      user,
      resolveUserRoleVariant,
      avatarText,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.buttons-edit {
  border-color: #18a8d6 !important;
  background-color: #18a8d6 !important;
  font-weight: bold;
}

.toastification .b-avatar {
  display: none !important;
}

.btn-swal {
  border-color: #18a8d6 !important;
  background-color: #18a8d6 !important;
  color: white !important;
}

.swal2-popup {
  border-radius: 30px !important;
}

.buttons-edit:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #18a8d6 !important;
}
.buttons-danger {
  border-color: #ef3a5d !important;
  background-color: #ef3a5d !important;
  font-weight: bold;
}

.flatpickr-calendar .flatpickr-day.selected {
  border-color: #18a8d6 !important;
  background-color: #18a8d6 !important;
}

.flatpickr-calendar .flatpickr-day.today {
  border-color: #18a8d6 !important;
}
</style>
